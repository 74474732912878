<template>
  <div
    class="login-container bg-gray-02 auth-bg"
    :class="{
      email: state.isEmailLogin,
      sns: state.isSnsLogin,
    }"
  >
    <!--	이메일 로그인  -->
    <img
      v-if="state.isEmailLogin"
      src="/assets/images/brand/logo/BIGCSTUDIO_Logotype_Black_2x_Comp.png"
      class="studio-logo"
      width="91"
      alt="BIGC-STUDIO black Logo"
    />
    <div v-if="state.isEmailLogin" class="login-form email-login-form">
      <div class="email-login">
        <img
          src="/assets/images/brand/logo/BIGCSTUDIO_Logotype_Black_2x_Comp.png"
          class="studio-logo"
          width="91"
          alt="BIGC-STUDIO black Logo"
        />
        <h2>이메일 로그인</h2>

        <form class="input-form" @submit.prevent="actions.onBtnEmailLogin()">
          <input-underline
            class="email-input"
            label="이메일"
            placeholder="example@bigc.im"
            name="email"
            type="email"
            :default-value="state.loginForm.email"
            :error-message="state.errorMessage.email"
            @updateData="
              (value) => {
                state.loginForm.email = value;
              }
            "
          ></input-underline>

          <input-underline
            class="password-input"
            label="비밀번호"
            placeholder="**********"
            name="password"
            type="password"
            :default-value="state.loginForm.password"
            :error-message="state.errorMessage.password"
            auto-complete="on"
            @updateData="
              (value) => {
                state.loginForm.password = value;
              }
            "
            @keypress.enter.stop="actions.onBtnEmailLogin()"
          ></input-underline>

          <div class="options">
            <input-checkbox
              :default-value="state.loginForm.isRemember"
              @updateData="
                (data) => {
                  actions.updateIsRemember(data);
                }
              "
            ></input-checkbox>
            <span class="sub-text-s3 text-gray-second">로그인 유지</span>

            <div class="find-pass">
              <router-link
                class="sub-text-s3 text-gray-second"
                :to="{ name: 'auth.findpwd' }"
              >
                비밀번호 찾기
              </router-link>
            </div>
          </div>

          <button-basic
            class="login-btn"
            text="로그인"
            type="summit"
          ></button-basic>
        </form>

        <div>
          <span
            class="sub-title-s3 text-gray-second"
            @click="
              () => {
                state.isSnsLogin = !state.isSnsLogin;
                state.isEmailLogin = !state.isEmailLogin;
              }
            "
          >
            다른 방법으로 로그인하기
          </span>
        </div>
      </div>
    </div>

    <!--	sns 로그인  -->
    <div v-if="state.isSnsLogin" class="login-form sns-login-form">
      <div class="sns-login">
        <img
          src="/assets/images/brand/logo/BIGCSTUDIO_Logotype_Black_2x_Comp.png"
          class="login-logo"
          width="120"
          alt="BIGC-STUDIO black Logo"
        />

        <h2>
          모든 영감은 크리에이터로부터-<br />
          지금 바로 시작하세요!
        </h2>

        <button-basic
          class="kakao-btn"
          text="카카오로 3초만에 시작하기"
          color="rgba(28,28,28,1)"
          bg-color="#fee500"
          hover-bg-color="#d8c300"
          icon-position="front"
          @action="actions.onBtnKakaotalkLogin()"
        >
          <template #icon>
            <img
              width="13"
              height="13"
              src="/assets/images/login/icons/kakao.svg"
            />
          </template>
        </button-basic>

        <p class="policy text-gray-third sub-text-s3">
          회원가입 시
          <a
            href="https://bigc-im.notion.site/bb7e427b25fc4443bdd9bbfe8d8f6c16"
            target="_blank"
          >
            <u class="text-gray-third">개인정보 처리방침</u>
          </a>
          및
          <a
            href="https://bigc-im.notion.site/0a271dd289b74905a9345c908a01e95c"
            target="_blank"
          >
            <u class="text-gray-third">이용약관</u>
          </a>
          동의로 간주됩니다.
        </p>

        <hr class="hr-third" />

        <div class="others">
          <button-basic
            class="social-btn"
            bg-color="#D0D5DE"
            shape="round"
            @action="actions.googleLogin"
          >
            <template #icon>
              <img
                class="svg-white"
                src="/assets/images/svg/ic_sns_google.svg"
                alt=""
              />
            </template>
          </button-basic>

          <!--note 페이스북 로그이 서비스 에러로, 페이스북 로그인,회원가입 중단하기로 결정함. https://www.notion.so/bigc-im/86092ec26624435fa7f7c1ee4585468b?pvs=4-->
          <button-basic
            v-if="false"
            class="social-btn"
            bg-color="#D0D5DE"
            shape="round"
            @action="actions.facebookLogin"
          >
            <template #icon>
              <img
                class="svg-white"
                src="/assets/images/svg/ic_sns_facebook.svg"
                alt=""
              />
            </template>
          </button-basic>

          <button-basic
            class="social-btn"
            bg-color="#D0D5DE"
            shape="round"
            @action="actions.showFbLoginQuitModal()"
          >
            <template #icon>
              <img
                class="svg-white"
                src="/assets/images/svg/ic_sns_facebook.svg"
                alt=""
              />
            </template>
          </button-basic>

          <button-basic
            class="social-btn"
            bg-color="#D0D5DE"
            shape="round"
            @action="actions.appleLogin"
          >
            <template #icon>
              <img
                class="svg-white"
                src="/assets/images/svg/ic_sns_apple.svg"
                alt=""
              />
            </template>
          </button-basic>

          <button-basic
            class="social-btn"
            bg-color="#D0D5DE"
            shape="round"
            @action="
              () => {
                state.isSnsLogin = !state.isSnsLogin;
                state.isEmailLogin = !state.isEmailLogin;
              }
            "
          >
            <template #icon>
              <img
                class="svg-white"
                width="20"
                src="/assets/images/svg/ic_mail.svg"
                alt=""
              />
            </template>
          </button-basic>

          <button-text
            class="join-btn"
            text="이메일로 가입하기"
            text-size="s2"
            color="#818287"
            @click="actions.moveToJoin()"
          ></button-text>
        </div>
      </div>
    </div>

    <teleport to="#teleport">
      <fb-login-fail-modal
        v-if="state.showFbLoginErrorModal"
        :fail-text="state.fbErrorMessage"
        @hideModal="actions.closeFbLoginErrorModal()"
      ></fb-login-fail-modal>
      <fb-login-quit-modal
        v-if="state.showFbLoginQuitModal"
        @hideModal="actions.closeFbLoginQuitModal()"
        @findPwd="actions.moveToFindPwd()"
      >
      </fb-login-quit-modal>
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onBeforeMount, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import InputCheckbox from "../../../components/console/inputs/InputCheckbox.vue";
import sns from "@/helper/sns";
import Cookies from "js-cookie";
import { googleTokenLogin } from "vue3-google-login";
import InputUnderline from "@/components/console/inputs/InputUnderline.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import ApiService from "../../../api";
import AuthService from "../../../services/AuthService";
import FbLoginFailModal from "./FbLoginFailModal/FbLoginFailModal";
import FbLoginQuitModal from "./FbLoginQuitModal/FbLoginQuitModal";

export default {
  name: "Login",
  components: {
    FbLoginQuitModal,
    ButtonText,
    ButtonBasic,
    InputUnderline,
    InputCheckbox,
    FbLoginFailModal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const kakao = ref(null);
    const FB = ref(null);
    const apple = ref(null);

    const redirectUriPrefix = `${process.env.VUE_APP_URL}/auth/handle`;

    const state = reactive({
      redirect: route.query?.redirect,
      loginForm: {
        email: "",
        password: "",
        isRemember: false,
      },
      errorMessage: {
        email: "",
        password: "",
        server: "",
      },
      isAuth: computed(() => {
        return store.getters["auth/check"];
      }),
      isEmailLogin: false,
      isSnsLogin: true,
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      showFbLoginErrorModal: false,
      fbErrorMessage: "",
      showFbLoginQuitModal: false,
    });

    const loginComplete = () => {
      if (state.user.aliasName) {
        Cookies.remove("onBoardingAlias");
      }
    };

    onBeforeMount(async () => {
      // 온보딩프로세스를 거쳤다면 대시보드로, 거치지 않았다면 온보딩으로 라우트 이동
      if (state.isAuth) {
        await goToNextRoute();
      }

      if (!window.Kakao.isInitialized()) {
        sns.kakaoInit();
      }
      kakao.value = window.Kakao;

      if (!window.FB) {
        await sns.initFacebookSdk();
      }
      FB.value = window.FB;

      FB.value.getLoginStatus((response) => {
        if (response.status === "connected") {
          FB.value.logout();
        }
      });

      sns.appleId(`${process.env.VUE_APP_URL}/auth/login`);
      apple.value = window.AppleID;
      if (route.params.isSnsUser) {
        state.isSnsLogin = true;
        state.isEmailLogin = false;
      }
    });

    const goToNextRoute = () => {
      if (!state.redirect) {
        router.push({
          name: "console.home",
        });
      } else {
        const redirectDecoded = decodeURIComponent(state.redirect);
        router.push({ path: redirectDecoded });
      }
    };

    const FBCallBack = async (response) => {
      if (!response.email) {
        state.showFbLoginErrorModal = true;
        state.fbErrorMessage =
          "페이스북 이메일 인증이 안된 계정입니다. 페이스북에서 이메일 인증 후 다시 로그인 해주세요.";
        FB.value.logout();
        return;
      }

      const payLoad = {
        providerId: response.id,
        providerEmail: response.email,
        providerName: response.name,
        providerNickname: response.name,
        providerAvatar: response.picture.data.url,
        provider: "facebook",
        providerPhoneNumber: null,
      };

      const res = await ApiService.postAuthSocialApp(payLoad);
      if (res.data.message) {
        console.error(`sns login API Error (facebook) : ${res.data.message}`);
      }
      if (!res.data.data) return;

      await AuthService.login(res.data.data.token, "/console/clubs");
    };

    const actions = {
      onBtnEmailLogin: async () => {
        if (!state.loginForm.email) {
          state.errorMessage.email = "이메일을 입력해 주세요.";
          return;
        }
        if (!state.loginForm.password) {
          state.errorMessage.password = "비밀번호를 입력해 주세요.";
          return;
        }
        await store
          .dispatch("auth/login", state.loginForm)
          .then(async (res) => {
            if (res.data.success) {
              await store.dispatch("auth/fetchUser");
              await loginComplete();
              await goToNextRoute();
            } else {
              state.errorMessage.email = res.data.data?.email;
              state.errorMessage.password = res.data.data?.password;
            }
          });
      },
      onBtnKakaotalkLogin: () => {
        const params = {
          redirectUri: `${redirectUriPrefix}/kakao`,
          scope: "account_email,gender",
        };
        kakao.value.Auth.authorize(params);
      },
      googleLogin: () => {
        googleTokenLogin().then(async (response) => {
          await sns.loginToGoogle(response);
          await loginComplete();
        });
      },
      facebookLogin: () => {
        FB.value.login((response) => {
          if (response.authResponse) {
            const { userID, accessToken } = response.authResponse;
            sns.loginToFacebook(FB.value, userID, accessToken, FBCallBack);
          }
        });
      },
      appleLogin: async () => {
        try {
          const data = await apple.value.auth.signIn();

          const appleUser = sns.appleTokenParseJwt(data.authorization.id_token);
          sns.loginToApple(appleUser);
        } catch (error) {
          console.log(error);
        }
      },
      moveToJoin: () => {
        router.push({
          name: "auth.join",
        });
      },
      updateIsRemember: (data) => {
        state.loginForm.isRemember = data;
      },
      closeFbLoginErrorModal: () => {
        state.showFbLoginErrorModal = false;
      },
      closeFbLoginQuitModal: () => {
        state.showFbLoginQuitModal = false;
      },
      showFbLoginQuitModal: () => {
        state.showFbLoginQuitModal = true;
      },
      moveToFindPwd: () => {
        router.push({
          name: "auth.findpwd",
        });
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>

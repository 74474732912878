<template>
  <div class="join-container auth-bg">
    <!--	이메일 로그인  -->
    <div>
      <img
        src="/assets/images/brand/logo/BIGCSTUDIO_Logotype_Black_2x_Comp.png"
        class="studio-logo"
        width="91"
        alt="BIGC-STUDIO black Logo"
      />
    </div>
    <div class="studio-join-form">
      <div class="studio-join-header">
        <img
          src="/assets/images/brand/logo/BIGCSTUDIO_Logotype_Black_2x_Comp.png"
          class="studio-logo"
          width="91"
          alt="BIGC-STUDIO black Logo"
        />
      </div>
      <div class="studio-join">
        <h2>이메일 회원가입</h2>

        <div class="name">
          <input-underline
            label="이름"
            placeholder="이름을 입력해주세요"
            name="name"
            :default-value="state.joinForm.name"
            :error-message="state.errorMessage.name"
            @updateData="
              (value) => {
                actions.updateName(value);
              }
            "
          ></input-underline>
        </div>
        <div class="email">
          <input-underline
            label="이메일 주소"
            placeholder="example@bigc.im"
            name="email"
            type="email"
            :default-value="state.joinForm.email"
            :error-message="state.errorMessage.email"
            @updateData="
              (value) => {
                actions.updateEmail(value);
              }
            "
          ></input-underline>
        </div>
        <div>
          <div class="phone">
            <div
              :class="{
                'border-primary':
                  state.joinForm.phone && !state.errorMessage.phone,
                'border-gray-third':
                  !state.joinForm.phone && !state.errorMessage.phone,
                'border-red-50': state.errorMessage.phone,
              }"
            >
              <div class="input-title">
                <label class="b-text-3">휴대폰 번호</label>
              </div>
              <div class="input-phone-number">
                <select
                  v-if="false"
                  class="country-code"
                  :value="state.joinForm.code"
                  @change="actions.updateCountryCode($event)"
                >
                  <option
                    v-for="(item, index) in countryCodes"
                    :key="`country-${index}`"
                    :value="item.dial_code"
                  >
                    {{ item.dial_code }}
                  </option>
                </select>
                <div class="default-country-code">
                  {{ state.joinForm.code }}
                </div>

                <input
                  class="phone-number b-text-1"
                  type="number"
                  :disabled="state.isPhoneValidationSuccess"
                  :value="state.joinForm.phone"
                  placeholder="숫자만 입력해주세요"
                  @input="actions.updatePhone($event)"
                />
              </div>
            </div>
            <button
              class="sub-text-s2 bg-gray-010"
              :class="{
                'text-gray-third':
                  !state.joinForm.phone || state.isPhoneValidationSuccess,
              }"
              :disabled="
                !state.joinForm.phone || state.isPhoneValidationSuccess
              "
              @click="actions.getPhoneValidationCode()"
            >
              {{ state.phoneValidateBtnTxt }}
            </button>
          </div>
          <div class="b-text-3 text-red-50 text-start">
            {{ state.errorMessage.phone }}
          </div>
        </div>

        <div>
          <div class="phone-confirm">
            <div
              :class="{
                'border-primary':
                  state.phoneValidationCode &&
                  !state.errorMessage.phoneValidationCode,
                'border-gray-third':
                  !state.phoneValidationCode &&
                  !state.errorMessage.phoneValidationCode,
                'border-red-50': state.errorMessage.phoneValidationCode,
              }"
            >
              <div class="input-title">
                <label class="b-text-3 text-truncate">인증번호</label>
              </div>
              <input
                type="number"
                :value="state.phoneValidationCode"
                :disabled="
                  !state.joinForm.phone || state.isPhoneValidationSuccess
                "
                placeholder="인증번호를 입력해주세요"
                @input="actions.updatePhoneValidationCode($event)"
              />
              <span
                v-if="state.sendValidateCode"
                class="text-red-50 authentication-time"
              >
                {{ state.displayTimer }}
              </span>
            </div>
            <button
              class="sub-text-s2 bg-gray-010"
              :class="{
                'text-gray-third':
                  !state.sendValidateCode || state.isPhoneValidationSuccess,
              }"
              :disabled="
                !state.sendValidateCode || state.isPhoneValidationSuccess
              "
              @click="actions.verifyPhoneVerificationCode"
            >
              인증하기
            </button>
          </div>
          <div class="b-text-3 text-red-50 text-start">
            {{ state.errorMessage.phoneValidationCode }}
          </div>
        </div>

        <div class="password">
          <input-underline
            label="비밀번호"
            placeholder="비밀번호를 입력해주세요"
            name="password"
            type="password"
            :default-value="state.joinForm.password"
            :error-message="state.errorMessage.password"
            @updateData="
              (value) => {
                actions.updatePassword(value);
              }
            "
          ></input-underline>
        </div>
        <div class="password-confirm">
          <input-underline
            label="비밀번호 확인"
            placeholder="비밀번호를 한번 더 입력해주세요"
            name="passwordConfirm"
            type="password"
            :default-value="state.joinForm.passwordConfirmation"
            :error-message="state.errorMessage.passwordValidation"
            @updateData="
              (value) => {
                actions.updatePasswordConfirmation(value);
              }
            "
          ></input-underline>
        </div>

        <div class="join-info text-gray-third">
          회원가입 시
          <a
            href="https://bigc-im.notion.site/bb7e427b25fc4443bdd9bbfe8d8f6c16"
            target="_blank"
          >
            <u class="text-gray-third">개인정보 처리방침 </u>
          </a>
          및
          <a
            href="https://bigc-im.notion.site/0a271dd289b74905a9345c908a01e95c"
            target="_blank"
          >
            <u class="text-gray-third">이용약관</u>
          </a>
          동의로 간주됩니다.
        </div>

        <div class="studio-join-btns">
          <button-basic
            color="#0d0d0d"
            bg-color="#ECF1F4"
            text="뒤로"
            @action="actions.moveToLogin()"
          ></button-basic>

          <button-basic
            text="가입하기"
            :disabled="!state.ableToJoin"
            @action="actions.joinStudio()"
          ></button-basic>
        </div>
        <p class="sub-text-s3 text-gray-second">
          이미 아이디가 있으신가요?
          <u class="" @click="actions.moveToLogin()">로그인</u>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import ApiService from "@/api";
import * as countryCode from "../../../consts/country-code.json";
import swal from "../../../helper/swal";
import { useStore } from "vuex";
import helper from "@/helper";
import { diffDay } from "../../../helper/date";
import moment from "moment-timezone";
import InputUnderline from "@/components/console/inputs/InputUnderline.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "Login",
  components: { ButtonBasic, InputUnderline },
  setup() {
    const router = useRouter();
    const store = useStore();

    const timer = ref();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      joinForm: {
        name: "",
        email: "",
        code: "+82",
        phone: "",
        password: "",
        passwordConfirmation: "",
      },
      phoneResourceId: "",
      phoneValidationCode: "",
      isPhone: computed(() => {
        return state.joinForm.phone.length > 0;
      }),
      errorMessage: {
        email: "",
        phone: "",
        phoneValidationCode: "",
        password: "",
        passwordValidation: "",
        server: "",
      },
      sendValidateCode: false,
      isPhoneValidationSuccess: false,
      phoneValidateBtnTxt: computed(() => {
        return state.sendValidateCode ? "재발송" : "인증번호 발송";
      }),
      ableToJoin: computed(() => {
        return (
          state.joinForm.name.length > 0 &&
          state.joinForm.email.length > 0 &&
          state.joinForm.code.length > 0 &&
          state.joinForm.phone.length > 0 &&
          state.joinForm.password.length > 0 &&
          state.joinForm.passwordConfirmation.length > 0 &&
          state.isPhoneValidationSuccess
        );
      }),
      displayTimer: "",
    });

    const countdownTimer = (expiredAt) => {
      let remainTime = diffDay(moment(expiredAt));

      if (remainTime.sec < 0) {
        clearInterval(timer.value);
        return;
      }
      state.displayTimer = `${remainTime.min}:${remainTime.sec}`;
    };

    const startTimer = (expiredAt) => {
      timer.value = setInterval(() => countdownTimer(expiredAt), 1000);
    };

    const countryCodes = countryCode.default;

    const actions = {
      moveToLogin: () => {
        router.push({
          name: "auth.login",
        });
      },
      updateName: (value) => {
        state.joinForm.name = value;
        if (state.errorMessage.name) {
          state.errorMessage.name = "";
        }
      },
      updateEmail: (value) => {
        state.joinForm.email = value;
        if (state.errorMessage.email) {
          state.errorMessage.email = "";
        }
      },
      updatePhone: (e) => {
        state.joinForm.phone = e.target.value;
        if (state.errorMessage.phone) {
          state.errorMessage.phone = "";
        }
      },
      updateCountryCode: (e) => {
        state.joinForm.code = e.target.value;
      },
      updatePhoneValidationCode: (e) => {
        state.phoneValidationCode = e.target.value;
        if (state.errorMessage.phoneValidationCode) {
          state.errorMessage.phoneValidationCode = "";
        }
      },
      getPhoneValidationCode: async () => {
        if (state.joinForm.code.length === 0) {
          return;
        }
        if (state.joinForm.phone.length === 0) {
          return;
        }

        if (!helper.validatePhoneNumber(state.joinForm.phone)) {
          state.errorMessage.phone = "전화번호 형식을 확인해주세요.";
          return;
        }

        if (state.phoneValidationCode) {
          state.phoneValidationCode = "";
        }
        if (state.errorMessage.phoneValidationCode) {
          state.errorMessage.phoneValidationCode = "";
        }
        if (state.displayTimer) {
          state.displayTimer = "";
        }

        // FIXME ROMANO 더 나은 코드로 수정 필요
        state.joinForm.phone = state.joinForm.phone
          .replaceAll("-", "")
          .replaceAll(".", "")
          .replaceAll("e", "");

        clearInterval(timer.value);

        let formData = new FormData();
        let phoneNumber = `${state.joinForm.code} ${state.joinForm.phone}`;
        formData.append("phone", phoneNumber);
        let payload = formData;

        await ApiService.postAuthPhoneNumber(payload).then((res) => {
          if (res.data.success) {
            state.sendValidateCode = true;
            state.phoneResourceId = res.data.data.phoneResourceId;
            swal.successToast("휴대폰으로 인증번호가 발송되었습니다.");

            startTimer(res.data.data.codeExpiredAt);
          }
        });
      },
      verifyPhoneVerificationCode: async () => {
        let formData = new FormData();
        formData.append("code", state.phoneValidationCode);
        let payload = formData;

        try {
          await ApiService.postAuthPhoneVerificationCode(
            state.phoneResourceId,
            payload
          ).then((res) => {
            if (res.data.success) {
              swal.successToast("인증되었습니다.");
              clearInterval(timer.value);
              state.displayTimer = "";
              state.isPhoneValidationSuccess = true;
            } else {
              state.errorMessage.phoneValidationCode = res.data.message;
            }
          });
        } catch (e) {
          if (e.response.status === 400) {
            state.errorMessage.phoneValidationCode = e.response.data.message;
          }
        }
      },
      updatePassword: (value) => {
        state.joinForm.password = value;
      },
      updatePasswordConfirmation: (value) => {
        state.joinForm.passwordConfirmation = value;
      },
      joinStudio: async () => {
        if (!helper.validateEmail(state.joinForm.email)) {
          state.errorMessage.email = "이메일 주소를 확인해주세요.";
          return;
        }

        let password = state.joinForm.password.trim();
        const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

        let existAlphabet = /[a-zA-Z]/.test(password);
        let existNumber = /\d/.test(password);
        let existSpecialCharacter = specialChars.test(password);
        let validateCount = 0;

        if (existAlphabet) {
          validateCount += 1;
        }
        if (existNumber) {
          validateCount += 1;
        }
        if (existSpecialCharacter) {
          validateCount += 1;
        }

        if (password.length < 6 || validateCount < 2) {
          return (state.errorMessage.password =
            "영문, 숫자, 특수문자를 조합해주세요. (6자리 이상)");
        }

        state.joinForm.password.trim();

        let passwordConfirmation = state.joinForm.passwordConfirmation.trim();

        if (password !== passwordConfirmation) {
          return (state.errorMessage.passwordValidation =
            "비밀번호와 비밀번호 확인이 일치하지 않습니다.");
        }

        let formData = new FormData();

        formData.append("name", state.joinForm.name);
        formData.append("email", state.joinForm.email);
        formData.append(
          "phone",
          `${state.joinForm.code} ${state.joinForm.phone}`
        );
        formData.append("password", state.joinForm.password);
        formData.append(
          "passwordConfirmation",
          state.joinForm.passwordConfirmation
        );

        let payload = formData;

        try {
          await ApiService.postAuthRegister(payload).then(async (res) => {
            if (res.data.success) {
              swal.successToast("가입되었습니다.");
              state.pageLoading = true;
              let token = res.data.data.token;
              await store.dispatch("auth/saveToken", {
                token,
                remember: true,
              });
              if (store.getters["auth/token"]) {
                let fetchUser = setInterval(() => {
                  store.dispatch("auth/fetchUser").then(async () => {
                    if (state.user.phone) {
                      clearInterval(fetchUser);
                      await router.push({ name: "console.home" });
                    }
                  });
                }, 1000);
              } else {
                router.push({
                  name: "console.home",
                });
              }
            } else {
              swal.errorToast(res.data.message);
            }
          });
        } catch (e) {
          if (e.response.status === 400) {
            state.errorMessage.email = e.response.data.data.email
              ? e.response.data.data.email[0]
              : "";
            state.errorMessage.password = e.response.data.data.password
              ? e.response.data.data.password[0]
              : "";
            state.errorMessage.passwordValidation = e.response.data.data
              .passwordConfirmation
              ? e.response.data.data.passwordConfirmation[0]
              : "";
            state.errorMessage.phone = e.response.data.data.phone
              ? e.response.data.data.phone[0]
              : "";
            state.errorMessage.phoneValidationCode = e.response.data.data.phone
              ? e.response.data.data.phone[0]
              : "";
          }
        }
      },
    };

    return { state, actions, countryCodes };
  },
};
</script>

<style src="./style.css" scoped></style>

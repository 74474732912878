<template>
  <base-modal-new class="fb-login-quit-modal" @closeModal="$emit('hideModal')">
    <template #modalTitle>
      <img
        src="/assets/images/brand/logo/BIGCSTUDIO_Logotype_Black_2x_Comp.png"
        class="studio-logo"
        width="91"
        alt="BIGC-STUDIO black Logo"
      />
    </template>
    <template #modalBody>
      <h3>페이스북 로그인/회원가입 <br class="mo" />중단 안내</h3>
      <div class="description">
        <p class="b-text-1">
          안녕하세요, 빅크입니다.<br />
          페이스북 인증 권한 및 정책의 문제로,<br /><strong
            >2023년 8월 31일 부터 페이스북 로그인 지원을 종료</strong
          >하게 되었습니다. <br />기존 유저분들의 불편을 최소화하기 위해 1개월
          전 사전 공지를 드립니다.
        </p>
        <p class="b-text-1">
          <strong>2023년 6월 13일 부터 페이스북 ID로 회원가입이 불가능</strong
          >하며, 기존 페이스북 로그인으로 서비스를 이용하신 유저분들은 아래의
          안내에 따라 계정 정보를 변경하시면 원활히 서비스를 이용하실 수
          있습니다.
        </p>
      </div>
      <div class="procedure-info bg-gray-015">
        <h4>계정 정보 변경은 어떻게 진행하나요?</h4>
        <div>
          <ul class="procedure-list">
            <li>
              <div class="number text-purple-20 b-title-1">STEP.01</div>
              <div class="text b-text-1">
                비밀번호 찾기 버튼을 클릭 후 페이스북 계정(이메일)을 입력해
                주세요.
              </div>
            </li>
            <li>
              <div class="number text-purple-20 b-title-1">STEP.02</div>
              <div class="text b-text-1">
                해당 이메일로 전송된 [비밀번호 변경링크]를 클릭하여 비밀번호를
                변경해 주세요.
              </div>
            </li>
            <li>
              <div class="number text-purple-20 b-title-1">STEP.03</div>
              <div class="text b-text-1">
                비밀번호 변경 후 이메일 로그인으로 서비스 이용이 가능합니다.
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="btn-wrapper">
        <button-text
          class="confirm-btn confirm-btn-text"
          text="비밀번호 찾기"
          text-size="s1"
          color="#ffffff"
          @click="$emit('findPwd')"
        ></button-text>
      </div>
    </template>
  </base-modal-new>
</template>

<script>
import { reactive, computed } from "vue";
import BaseModalNew from "../../../../components/common/BaseModalNew.vue";
import ButtonText from "../../../../components/console/buttons/ButtonText";

export default {
  name: "FbLoginQuitModal",
  components: { ButtonText, BaseModalNew },
  props: {
    failText: {
      type: String,
      required: true,
    },
  },
  emits: ["hideModal", "findPwd"],
  setup(props) {
    const state = reactive({});

    return { state };
  },
};
</script>

<style scoped>
.btn-wrapper {
  margin-top: 32px;
}

.confirm-btn {
  width: 100%;
  background-color: #8e1eff;
}

.confirm-btn-text {
  justify-content: center;
}

.fb-login-quit-modal h3 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.description {
  margin-bottom: 24px;
}

.description p {
  margin-bottom: 0;
}

.description p:first-child {
  margin-bottom: 20px;
}

.procedure-info {
  padding: 20px 16px;
  border-radius: 8px;
}

.procedure-info h4 {
  text-align: center;
  margin-bottom: 20px;
}

.procedure-list li {
  display: flex;
  gap: 12px;
  margin-bottom: 0;
}

.procedure-list .number {
  font-weight: 700;
  line-height: 130%;
}

.procedure-list li:not(:last-child) {
  margin-bottom: 12px;
}

.procedure-list li .number {
  align-self: center;
}

@media (min-width: 768px) {
  .mo {
    display: none;
  }

  .fb-login-quit-modal h3 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .procedure-info {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>

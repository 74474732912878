<template>
  <base-modal-new
    title="로그인에 실패했어요"
    :sub-title="failText"
    @closeModal="$emit('hideModal')"
  >
    <template #modalBody>
      <div class="btn-wrapper">
        <button-text
          class="confirm-btn"
          text="확인"
          text-size="s1"
          color="#ffffff"
          @click="$emit('hideModal')"
        ></button-text>
      </div>
    </template>
  </base-modal-new>
</template>

<script>
import { reactive, computed } from "vue";
import BaseModalNew from "../../../../components/common/BaseModalNew.vue";
import ButtonText from "../../../../components/console/buttons/ButtonText";

export default {
  name: "AddBrandSnsModal",
  components: { ButtonText, BaseModalNew },
  props: {
    failText: {
      type: String,
      required: true,
    },
  },
  emits: ["hideModal"],
  setup(props) {
    const state = reactive({});

    return { state };
  },
};
</script>

<style scoped>
.btn-wrapper {
  margin-top: 32px;
}

.confirm-btn {
  width: 100%;
  background-color: #8e1eff;
}
</style>

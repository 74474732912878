<template>
  <div class="input-underline">
    <div class="label-wrapper">
      <label v-if="label" class="sub-text-s3">
        {{ label }}
      </label>
      <p v-if="!!errorMessage" class="error-msg sub-text-s3 text-red-50">
        {{ errorMessage }}
      </p>
    </div>

    <input
      class="b-text-1"
      :class="{ error: !!errorMessage }"
      :name="name"
      :type="type"
      :value="defaultValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :autocomplete="autoComplete"
      @input="actions.updateData($event)"
    />
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "InputUnderline",
  props: {
    defaultValue: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    errorMessage: {
      type: String,
      required: false,
    },
    autoComplete: {
      type: String,
      default: "on",
      validator(value) {
        return ["on", "off"].includes(value);
      },
    },
  },
  emits: ["updateData"],
  setup(props, { emit }) {
    const state = reactive({});

    const actions = {
      updateData: (e) => {
        emit(
          "updateData",
          props.type === "number" ? parseInt(e.target.value) : e.target.value
        );
      },
    };

    return { state, actions };
  },
};
</script>

<style scoped>
.input-underline {
  width: 100%;
}

.label-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.error-msg {
  margin-bottom: 0;
}

input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e6eaef;
  padding: 7px 0;
  width: 100%;
  background: transparent;
}

input::placeholder {
  color: #d0d5de;
}

input:not(:placeholder-shown) {
  border-color: #9ea0a6;
}
input:focus {
  outline: none;
  border-color: #8e1eff;
}

input.error {
  border-color: #ff4646;
}
input:disabled {
  border-color: #e6eaef;
  color: #d0d5de;
  background: transparent;
}
</style>
